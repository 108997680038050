import { Link } from "react-router-dom";
import { navigateTo } from "../utils";
import React, { useContext } from "react";
import { TestNetContext } from "../pages/home";


type PaginatorProps = {
    show: boolean;
    paramSearch: string;
    paramBlocksListPage: number;
    paramBlocksTablePage: number;
    anchorName?: string;
};

export function PaginatorBack(props: PaginatorProps) {
    const testNet = useContext(TestNetContext);

    return props.show
        ? <Link
            to={navigateTo(props.paramSearch, props.paramBlocksListPage, props.paramBlocksTablePage, testNet)}
            style={{ color: 'black', textDecoration: 'none', paddingRight: 20 }}
            onClick={() => props.anchorName && document.getElementById(props.anchorName)?.scrollIntoView({ behavior: 'smooth' })}>
            <img style={{ width: '10px' }} src="/images/icon-back.svg" alt="Back" />
            BACK
        </Link>
        : <></>;
}

export function PaginatorNext(props: PaginatorProps) {
    const testNet = useContext(TestNetContext);

    return props.show
        ? <Link
            to={navigateTo(props.paramSearch, props.paramBlocksListPage, props.paramBlocksTablePage, testNet)}
            style={{ color: 'black', textDecoration: 'none', paddingLeft: 20 }}
            onClick={() => props.anchorName && document.getElementById(props.anchorName)?.scrollIntoView({ behavior: 'smooth' })}>
            NEXT
            <img style={{ width: '10px' }} src="/images/icon-next.svg" alt="Next" />
        </Link>
        : <></>;
}
