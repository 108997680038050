import { useContext, useEffect, useState } from "react";
import { StoredRecord } from "../dto/storedRecord";
import { BlockListCard } from "./blockListCard";
import { getUniqueId } from "../api";
import { TestNetContext } from "../pages/home";

type BlocksListProps = {
    blocks: StoredRecord[];
    latestHash: string,
    getNavigateTo: (search: string) => string,
    refreshStoredRecord: (uniqueId: string, storedRecord: StoredRecord) => void,
    testNet: string,
};

export default function BlocksList(props: BlocksListProps) {
    const [openedCard, setOpenedCard] = useState("");
    const testNet = useContext(TestNetContext);

    if (openedCard !== "" && props.blocks.findIndex(b => b.uniqueId === openedCard) === -1) {
        setOpenedCard("");
    }

    useEffect(() => {
        const fetchData = async () => {
            getUniqueId(openedCard, testNet).then(s => {
                if (s !== undefined) {
                    props.refreshStoredRecord(openedCard, s);
                }
            })
        }

        let intervalId: NodeJS.Timeout | undefined = undefined;

        if (openedCard !== "") {
            fetchData();
            intervalId = setInterval(fetchData, 3_000);
        }

        return () => clearInterval(intervalId);
    }, [openedCard, props, testNet]);

    if (props.blocks.length === 0) {
        return <div className="cards-container">
            <div key="notFound" className="card">
                <div className="card-content">
                    <img src="/images/notfound.svg" alt="Not found" style={{ width: '40px', paddingRight: '10px' }} />
                    <div className="card-left">
                        There are not ChaMblocks found for this search string
                    </div>
                </div>
            </div>
        </div>;
    }

    return <div className="cards-container">
        {props.blocks && props.blocks.map((b) =>
            <BlockListCard key={b.uniqueId} isLatestHash={b.data.record.header.chamelyonHash.hash === props.latestHash} selected={b.uniqueId === openedCard} setOpenedCard={setOpenedCard} storedRecord={b} getNavigateTo={props.getNavigateTo} />
        )}
    </div>;
}