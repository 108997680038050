import { StoredRecord, Record } from "../dto/storedRecord";
import { capitalize, formatDate } from "../utils";
import { BlockTypeEnum } from "../dto/block";
import ShowHash from "./showHash";

type BlocksTableProps = {
    blocks: StoredRecord[];
    getNavigateTo: (search: string) => string
};

export default function BlocksTable(props: BlocksTableProps) {
    const modified = (record: Record) => {
        switch (record.block.$blockType) {
            case BlockTypeEnum.Social_1_0:
            case BlockTypeEnum.Article_1_0:
            case BlockTypeEnum.Privacy_0_1:
                return <img src={(record.block.revisions?.length ?? 0) > 0 ? '/images/icon-cube-modified.svg' : '/images/icon-cube.png'} alt="Icon" style={{ width: '20px' }} />;
            default:
                return <></>;
        }
    }

    const blockType = (record: Record) => {
        let subType = "";
        switch (record.block.$blockType) {
            case BlockTypeEnum.Social_1_0:
                subType = record.block.social as string;
                break;
            default:
                subType = "";
        }
        return capitalize(record.header.blockType.name) + ((subType && "/") ?? "") + subType;
    }

    const timestamp = (record: Record) => {
        switch (record.block.$blockType) {
            case BlockTypeEnum.Social_1_0:
            case BlockTypeEnum.Article_1_0:
            case BlockTypeEnum.Privacy_0_1:
                return formatDate(record.block.timestamp);
            default:
                return "";
        }
    }

    return <tbody>
        {props.blocks.map(b =>
            <tr key={b.uniqueId}>
                <td><span className="inline-icon">{modified(b.data.record)}</span></td>
                <td>{b.uniqueId}</td>
                <td>
                    <ShowHash navigateTo={() => props.getNavigateTo(b.uniqueId)} hash={b.data.record.header.chamelyonHash.hash} />
                </td>
                <td>{blockType(b.data.record)}</td>
                <td>{timestamp(b.data.record)}</td>
            </tr>
        )}
    </tbody>;
}