import { useState } from "react";
import { normalizePrivacyText } from "../utils";
import { normalizeSocialLinkedinText } from "../linkedin";

export default function Utility() {
    const [text, setText] = useState("");
    const [unicode, setUnicode] = useState(true);

    const normalizeJSONString = (text: string) => {
        text = text.replace(/\\/g, "\\\\");
        text = text.replace(/\n/g, "\\n");
        text = text.replace(/"/g, "\\\"");
        return text.trim();
    };

    const escapeUnicode = (text: string): string => {
        return unicode
            ? text.replace(/[\u007f-\uffff]/g,
                function (c) {
                    return '\\u' + ('0000' + c.charCodeAt(0).toString(16).toUpperCase()).slice(-4);
                })
            : text;
    };

    return <div>
        <textarea cols={150} rows={30} value={text} onChange={(e) => setText(e.currentTarget.value)} />
        <br />
        <br />
        <input type="checkbox" onChange={(e) => setUnicode(e.currentTarget.checked)} checked={unicode} />Unicode escape<br />
        <br />
        <button onClick={async () => setText(escapeUnicode(await normalizeSocialLinkedinText(text)))}>Normalize Linkedin</button><br />
        <button onClick={() => setText(escapeUnicode(normalizePrivacyText(text)))}>Normalize Privacy</button><br />
        <button onClick={() => setText(escapeUnicode(normalizeJSONString(text)))}>JSON string</button><br />
    </div>;
}
