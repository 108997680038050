type StringOrLinkProps = {
    string: string;
    label: string;
    label2?: string;
};


export default function StringOrLink(props: StringOrLinkProps) {
    return props.string?.startsWith("https://") || props.string?.startsWith("http://")
        ? <a target="_blank" rel="noreferrer" href={props.string}>{props.label}</a>
        : <>{props.string}{(props.label2 === undefined || props.label2 === null) ? "" : " - "}{props.label2 ?? ""}</>;
}
