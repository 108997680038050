import { PrivacyBlock } from "../dto/privacy";
import { formatDate } from "../utils";
import { BlockCardProps } from "./blockCard";
import CopyClipboard from "./copyClipboard";
import ShowHash from "./showHash";

export function BlockCardPrivacy(props: BlockCardProps) {
    const block = props.storedRecord.data.record.block as PrivacyBlock;

    return <table className="card-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
            <tr>
                <td>ChaMblock:</td>
                <td><p className="chaMblock">{props.storedRecord.uniqueId} - privacy</p></td>
            </tr>
            <tr>
                <td>Chamelyon Hash:</td>
                <td>
                    <p>
                        <ShowHash navigateTo={() => props.getNavigateTo(props.storedRecord.uniqueId)} hash={props.storedRecord.data.record.header.chamelyonHash.hash} />
                    </p>
                </td>
            </tr>
            <tr>
                <td>Title:</td>
                <td>
                    <p>
                        {block.title}
                    </p>
                </td>
            </tr>
            <tr>
                <td>Content:</td>
                <td>
                    <p>
                        {block.content.slice(0, 30)}{block.content.length > 30 ? "..." : ""}
                        <CopyClipboard text={block.content} />
                    </p>
                </td>
            </tr>
            <tr>
                <td>Author:</td>
                <td><p className="author">{block.author}</p></td>
            </tr>
            <tr>
                <td>Privacy:</td>
                <td>{block.jurisdiction}/{block.language}/{block.version}</td>
            </tr>
            {block.documents && block.documents.length > 0 &&
                <tr>
                    <td>Documents:</td>
                    <td>
                        <ul style={{ listStyleType: "none", listStyle: "none", padding: "0", margin: "0" }}>
                            {block.documents.map(d => <li key={d.digest}>
                                <a target="_blank" rel="noreferrer" href={d.uri}>{d.uri.slice(0, 30)}{d.uri.length > 30 ? "..." : ""}</a>
                            </li>)}
                        </ul>
                    </td>
                </tr>
            }
            <tr>
                <td>Timestamp:</td>
                <td><p className="timestamp">{formatDate(block.timestamp)}</p></td>
            </tr>
        </tbody>
    </table>;
}