import { createContext, useEffect, useMemo, useState } from "react";
import { StoredRecord } from "../dto/storedRecord";
import { getLatests, getSearch, getStat } from "../api";
import BlocksList from "../components/blocksList";
import Copyright from "../components/copyright";
import FacebookLink from "../components/facebookLink";
import InstagramLink from "../components/instagramLink";
import LinkedinLink from "../components/linkedinLink";
import BlocksTable from "../components/blocksTable";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { navigateTo, reverseArray, startFromOne } from "../utils";
import { PaginatorBack, PaginatorNext } from "../components/paginator";
import { LastHash, Stat } from "../dto/stat";
import { BlockTypeEnum } from "../dto/block";
import Graph from "../components/graph";
import ShowHash from "../components/showHash";

export const TestNetContext = createContext("");

export default function Home() {
    const navigate = useNavigate();

    const params = useParams();
    const paramSearch = params.search ?? "";
    const [search, setSearch] = useState(paramSearch);
    const paramTestNet = params.testNet ?? "";

    const [searchParams] = useSearchParams();
    const paramBlocksListPage = startFromOne(searchParams.get("bl"));
    const paramBlocksTablePage = startFromOne(searchParams.get("bt"));

    const [firstLoad, setFirstLoad] = useState(true);
    const [listLoading, setListLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [blockList, setBlockList] = useState<StoredRecord[]>([]);
    const [blockTable, setBlockTable] = useState<StoredRecord[]>([]);
    const [showNextBlocksList, setShowNextBlocksList] = useState(false);
    const [showBackBlocksList, setShowBackBlocksList] = useState(false);
    const [showNextBlocksTable, setShowNextBlocksTable] = useState(false);
    const [showBackBlocksTable, setShowBackBlocksTable] = useState(false);

    const [stat, setStat] = useState<Stat>();

    const lastHash = useMemo(() => {
        if (stat === undefined) {
            return { date: "", hash: "", uniqueId: "" } as LastHash;
        }
        if (stat.lastHashes === undefined) {
            return { date: "", hash: "", uniqueId: "" } as LastHash;
        }

        const last = Object.keys(stat.lastHashes).reduce((pv, cv) => {
            if (pv === "") {
                return cv;
            }
            return stat.lastHashes[cv as BlockTypeEnum].date >= stat.lastHashes[pv as BlockTypeEnum].date ? cv : pv;
        }, "")

        if (last === "") {
            return { date: "", hash: "", uniqueId: "" } as LastHash;
        }

        return stat.lastHashes[last as BlockTypeEnum];

    }, [stat])

    useEffect(() => {
        const fetchData = async () => {
            setShowNextBlocksList(false);
            setShowBackBlocksList(false);
            setListLoading(true);

            if (paramSearch.length > 0) {
                getSearch(paramSearch, paramBlocksListPage, paramTestNet).then(b => {
                    setShowNextBlocksList(b.length === 10 + 1);
                    setShowBackBlocksList(paramBlocksListPage > 1);
                    setBlockList(b.length <= 10 ? b : b.slice(0, 10));
                }).finally(() => {
                    setFirstLoad(false);
                    setListLoading(false);
                });
            }
            else {
                getLatests(10, paramBlocksListPage, paramTestNet).then(b => {
                    setShowNextBlocksList(b.length === 10 + 1);
                    setShowBackBlocksList(paramBlocksListPage > 1);
                    setBlockList(b.length <= 10 ? b : b.slice(0, 10));
                }).finally(() => {
                    setFirstLoad(false);
                    setListLoading(false);
                });
            }
            setSearch(paramSearch);
        };

        fetchData();
    }, [paramSearch, paramBlocksListPage, setShowNextBlocksList, setShowBackBlocksList, paramTestNet]);

    useEffect(() => {
        const fetchData = async () => {
            setShowNextBlocksTable(false);
            setShowBackBlocksTable(false);
            setTableLoading(true);

            getLatests(88, paramBlocksTablePage, paramTestNet).then(b => {
                setShowNextBlocksTable(b.length === 88 + 1);
                setShowBackBlocksTable(paramBlocksTablePage > 1);
                setBlockTable(b.length <= 88 ? b : b.slice(0, 88));
            }).finally(() => {
                setTableLoading(false);
            });
        };

        fetchData();
    }, [paramBlocksTablePage, setShowNextBlocksTable, setShowBackBlocksTable, paramTestNet]);

    useEffect(() => {
        const fetchData = async () => {
            getStat(paramTestNet).then(s => {
                setStat(s);
            })
        }

        fetchData();
        const intervalId = setInterval(fetchData, 10_000);
        return () => clearInterval(intervalId);
    }, [setStat, paramTestNet]);


    const graphDay = {
        labels: reverseArray(Object.keys(stat?.lastDayRecords ?? [])),
        datasets: [
            {
                label: 'Blocks',
                data: reverseArray(Object.values(stat?.lastDayRecords ?? [])),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderWidth: 1,
            },
            {
                label: 'Checks',
                data: reverseArray(Object.values(stat?.lastDayChecks ?? [])),
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderWidth: 1,
            },
        ],
    };

    const graphWeek = {
        labels: reverseArray(Object.keys(stat?.lastWeekRecords ?? [])),
        datasets: [
            {
                label: 'Blocks',
                data: reverseArray(Object.values(stat?.lastWeekRecords ?? [])),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderWidth: 1,
            },
            {
                label: 'Checks',
                data: reverseArray(Object.values(stat?.lastWeekChecks ?? [])),
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <TestNetContext.Provider value={paramTestNet}>
            <div className="main-container">
                <div className="container-desktop">
                    <div className="logo">
                        <Link to={getNavigateTo("")}><img src="/images/logo.png" alt="Logo" /></Link>
                    </div>
                    <div className="menu desktop-menu">
                        <a target="_blank" rel="noreferrer" href="https://www.chamelyon.com/">ABOUT US</a>
                        <a target="_blank" rel="noreferrer" href="https://discord.gg/HRYa7ywNTj">COMMUNITY</a>
                        <a target="_blank" rel="noreferrer" href="https://www.chamelyon.com/donation/">SUPPORT US</a>
                    </div>
                </div>

                <div className="container-mobile">
                    <div className="logo">
                        <Link to={getNavigateTo("")}><img src="/images/logo.png" alt="Logo" /></Link>
                    </div>
                    <div className="menu mobile-menu">
                        <a target="_blank" rel="noreferrer" href="https://www.chamelyon.com/">ABOUT US</a>
                        <a target="_blank" rel="noreferrer" href="https://discord.gg/HRYa7ywNTj">COMMUNITY</a>
                        <a target="_blank" rel="noreferrer" href="https://www.chamelyon.com/donation/">SUPPORT US</a>
                    </div>
                </div>

                <div className="explorer-container">
                    <div style={{ margin: '0 5% 2% 5%' }} >
                        <div className="search-bar">
                            <input type="text" placeholder="Search by Address / Hash / Block / Author / Content / ..." onChange={handleChangeSearch} value={search} onKeyDown={handleKeyDownSearch} />
                            {search.length > 0 &&
                                <button className="search-button" onClick={handleClickSearchClear}>
                                    <img className="clear" src="/images/icon-search-clear.svg" alt="Clear" />
                                </button>
                            }
                            <button className="search-button" onClick={handleClickSearch}>
                                <img className="search" src="/images/icon-search.svg" alt="Search" />
                            </button>
                        </div>
                        <div className="latest-hash">
                            <span>Latest hash: </span>
                            <ShowHash navigateTo={() => getNavigateTo(lastHash.uniqueId)} hash={lastHash.hash} />
                        </div>
                        <div className="bar-container">
                            <ul style={{ listStyleType: "none", listStyle: "none", padding: "10px", margin: "0" }}>
                                <li>Blocks: {stat?.recordCount}</li>
                                <li>Checks: {stat?.checkCount}</li>
                                <li className="alignment-graphs"><Graph data={graphDay} title="Last Day" height={120} /><Graph data={graphWeek} title="Last Week" height={120} /></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="complex-container">
                    <div className="first-column">
                        <div className="subcolumn image-column">
                            <img src="/images/icon-cube.png" style={{ width: '50px' }} alt="Block" />
                        </div>
                        <div className="subcolumn">
                            <p>Blocks</p>
                            <p>{stat?.recordCount}</p>
                        </div>
                        <div className="subcolumn">
                            <p>Checks</p>
                            <p>{stat?.checkCount}</p>
                        </div>
                        <div className="subcolumn image-column last-column">
                            <Graph data={graphDay} title="Last Day" />
                        </div>
                    </div>
                    <div className="second-column">
                        <div className="subcolumn image-column">
                            <Graph data={graphWeek} title="Last Week" />
                        </div>
                        <div className="subcolumn">
                            <p>
                                <a className="white-link" href="https://chromewebstore.google.com/detail/chamelyon-for-linkedin/ojencakindbmjhaehghemlfblglmajbi" rel="noreferrer" target="_blank">
                                    30/July/2024 - Chamelyon Linkedin Plugin released
                                </a>
                            </p>
                            <img src="/images/linkedin-icon.png" alt="Linkedin logo" style={{ width: "50px" }} />
                            <div className="badge-news">NEWS</div>
                        </div>
                    </div>
                </div>

                <div className="fourth-container">
                    <div className="first-column-complex">
                        {!firstLoad &&
                            <>
                                <span id="anchorList"></span>
                                <div style={{ paddingTop: '30px', paddingBottom: '20px', color: 'black', textAlign: 'center' }}>
                                    <PaginatorBack show={showBackBlocksList} paramBlocksListPage={paramBlocksListPage - 1} paramBlocksTablePage={paramBlocksTablePage} paramSearch={paramSearch} />
                                    {showBackBlocksList || showNextBlocksList ? "Page: " + paramBlocksListPage : listLoading ? "Loading..." : <br />}
                                    <PaginatorNext show={showNextBlocksList} paramBlocksListPage={paramBlocksListPage + 1} paramBlocksTablePage={paramBlocksTablePage} paramSearch={paramSearch} />
                                </div>
                                <BlocksList blocks={blockList} latestHash={lastHash.hash} getNavigateTo={getNavigateTo} refreshStoredRecord={refreshStoredRecord} testNet={paramTestNet} />
                                <div style={{ color: 'black', textAlign: 'center' }}>
                                    <PaginatorBack show={showBackBlocksList} paramBlocksListPage={paramBlocksListPage - 1} paramBlocksTablePage={paramBlocksTablePage} paramSearch={paramSearch} anchorName="anchorList" />
                                    {showBackBlocksList || showNextBlocksList ? "Page: " + paramBlocksListPage : listLoading ? "Loading..." : <br />}
                                    <PaginatorNext show={showNextBlocksList} paramBlocksListPage={paramBlocksListPage + 1} paramBlocksTablePage={paramBlocksTablePage} paramSearch={paramSearch} anchorName="anchorList" />
                                </div>
                            </>
                        }
                    </div>
                    <div className="second-column-complex">
                        <span id="anchorTable"></span>
                        <div style={{ paddingTop: '30px', paddingBottom: '20px', color: 'black', textAlign: 'center' }}>
                            <PaginatorBack show={showBackBlocksTable} paramBlocksListPage={paramBlocksListPage} paramBlocksTablePage={paramBlocksTablePage - 1} paramSearch={paramSearch} />
                            {showBackBlocksTable || showNextBlocksTable ? "Page: " + paramBlocksTablePage : tableLoading ? "Loading..." : <br />}
                            <PaginatorNext show={showNextBlocksTable} paramBlocksListPage={paramBlocksListPage} paramBlocksTablePage={paramBlocksTablePage + 1} paramSearch={paramSearch} />
                        </div>
                        <div className="table-wrapper">
                            <table className="chamblock-table">
                                <thead>
                                    <tr>
                                        <th style={{ background: 'white' }}></th>
                                        <th><h4>ChaMblock</h4></th>
                                        <th><h4>Hash</h4></th>
                                        <th><h4>Type</h4></th>
                                        <th><h4>Timestamp</h4></th>
                                    </tr>
                                </thead>
                                <BlocksTable blocks={blockTable} getNavigateTo={getNavigateTo} />
                            </table>
                        </div>
                        <div style={{ color: 'black', textAlign: 'center' }}>
                            <PaginatorBack show={showBackBlocksTable} paramBlocksListPage={paramBlocksListPage} paramBlocksTablePage={paramBlocksTablePage - 1} paramSearch={paramSearch} anchorName="anchorTable" />
                            {showBackBlocksTable || showNextBlocksTable ? "Page: " + paramBlocksTablePage : tableLoading ? "Loading..." : <br />}
                            <PaginatorNext show={showNextBlocksTable} paramBlocksListPage={paramBlocksListPage} paramBlocksTablePage={paramBlocksTablePage + 1} paramSearch={paramSearch} anchorName="anchorTable" />
                        </div>
                    </div>
                </div>

                <footer className="footer">
                    <div className="footer-top">
                        <div className="footer-left">
                            <img src="/images/logo.png" alt="Logo" className="footer-logo" />
                            <p>Chamelyon Scan is the unique Block Explorer and Analytics Platform for Chamelyon Network.</p>
                            <a target="_blank" rel="noreferrer" href="https://www.chamelyon.com/donation/" className="support-button" style={{ textDecoration: 'none' }} >SUPPORT US ➤</a>
                        </div>
                        <div className="footer-right">
                            <h3>CONTACTS</h3>
                            <p>
                                <img src="/images/icon-email.svg" alt="Mail Icon" className="email-icons" />
                                <a href="mailto:info@chamelyon.com" style={{ color: 'white' }}>info@chamelyon.com</a>
                            </p>
                            <div className="social-icons">
                                <FacebookLink />
                                <InstagramLink />
                                <LinkedinLink />
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="footer-bottom-left">
                            <Copyright />
                        </div>
                        <div className="footer-bottom-right">
                            <a target="_blank" rel="noreferrer" href="https://www.chamelyon.com/privacy-policy/">Privacy Policy</a>
                        </div>
                    </div>
                </footer >
                <script src="js/card.js" defer />
                <script src="js/table.js" defer />
                <script src="js/complex-container.js" defer />
            </div >
        </TestNetContext.Provider>
    );

    function handleChangeSearch(e: React.FormEvent<HTMLInputElement>) {
        setSearch(e.currentTarget.value);
    }

    function handleClickSearch(_: React.FormEvent<HTMLButtonElement>) {
        const page = search === paramSearch ? paramBlocksListPage : 1;
        navigate(navigateTo(search, page, paramBlocksTablePage, paramTestNet));
    }

    function handleClickSearchClear(_: React.FormEvent<HTMLButtonElement>) {
        setSearch("");
        navigate(navigateTo("", 1, paramBlocksTablePage, paramTestNet));
    }

    function handleKeyDownSearch(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            const page = search === paramSearch ? paramBlocksListPage : 1;
            navigate(navigateTo(search, page, paramBlocksTablePage, paramTestNet));
        }
    }

    function getNavigateTo(newSearch: string): string {
        const page = newSearch === paramSearch ? paramBlocksListPage : 1;
        return navigateTo(newSearch, page, paramBlocksTablePage, paramTestNet);
    }

    function refreshStoredRecord(uniqueId: string, storedRecord: StoredRecord) {
        for (let i = 0; i < blockList.length; i++) {
            if (blockList[i].uniqueId === uniqueId) {
                blockList[i] = storedRecord;
                setBlockList(blockList);
                break;
            }
        }
    }
}
