import { capitalize, formatDate } from "../utils";
import { BlockCardProps } from "./blockCard";
import ShowHash from "./showHash";

export function BlockCardGeneric(props: BlockCardProps) {
    const block = props.storedRecord.data.record.block;

    return <table className="card-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
            <tr>
                <td>ChaMblock:</td>
                <td><p className="chaMblock">{props.storedRecord.uniqueId}</p></td>
            </tr>
            <tr>
                <td>Chamelyon Hash:</td>
                <td>
                    <p>
                        <ShowHash navigateTo={() => props.getNavigateTo(props.storedRecord.uniqueId)} hash={props.storedRecord.data.record.header.chamelyonHash.hash} />
                    </p>
                </td>
            </tr>
            <tr>
                <td>Type:</td>
                <td><p className="social">{capitalize(props.storedRecord.data.record.header.blockType.namespace)}/{capitalize(props.storedRecord.data.record.header.blockType.name)}</p></td>
            </tr>
            <tr>
                <td>Timestamp:</td>
                <td><p className="timestamp">{formatDate(block.timestamp)}</p></td>
            </tr>
        </tbody>
    </table>;
}