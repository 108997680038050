const extractLinkedinUrls = (text: string) => {
    const urlPattern = /https:\/\/lnkd\.in\/\S+/g;
    return text.match(urlPattern) || [];
};

export const normalizeSocialLinkedinText = async (text: string) => {
    const JSONHeaders = new Headers();
    JSONHeaders.append("Content-Type", "application/json");

    const linkedinUrls = await Promise.all(extractLinkedinUrls(text).map(async url => {
        const newUrl = await fetch("https://dcbt.chamelyon.com/explorer/unshorten", {
            method: "POST",
            body: JSON.stringify({ url: url }),
            headers: JSONHeaders,
        }).then(async res => res.ok
            ? await res.text()
                .then(html => html)
                .catch(_ => url)
            : res.status === 302
                ? res.headers.get("location") ?? url
                : url)
            .catch(_ => url);
        return { url: url, newUrl: newUrl };
    }));

    linkedinUrls.forEach(linkedinUrl => text = text.replace(linkedinUrl.url, linkedinUrl.newUrl));

    // const sUsrAg = navigator.userAgent;
    // if (sUsrAg.indexOf("Firefox") > -1) {
    text = text.replace(/hashtag#/g, "#");
    // }
    // The extension use contentText to extract the text from the Linkedin's Post/Comment and all the \n are replaced with ''
    text = text.replace(/\r?\n/g, "");
    text = text.replace(/[\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u200B\u202F\u205F\u3000\uFEFF]/g, " ");
    text = text.replace(/\s{2,}/g, " ");

    return text.trim();
};
