import { Stat } from "./dto/stat";
import { StoredRecord } from "./dto/storedRecord";

const headers = new Headers({
    // 'Authorization': `Basic ${btoa('great:team')}`
});

const testNetParam = (testNet: string, multiple: boolean = false) => testNet === "" ? "" : (multiple ? "&" : "?") + "testNet=" + testNet;

export async function getLatests(howMany: number, page: number, testNet: string): Promise<StoredRecord[]> {
    return await fetch('https://dcbt.chamelyon.com/Explorer/Last/' + howMany + '/' + page + testNetParam(testNet), { headers: headers })
        .then(res => res.ok ? res.json() : [])
        .catch(() => []);
}

export async function getSearch(search: string, page: number, testNet: string): Promise<StoredRecord[]> {
    return await fetch('https://dcbt.chamelyon.com/Explorer/Search/10/' + page + '?search=' + search + testNetParam(testNet, true), { headers: headers })
        .then(res => res.ok ? res.json() : [])
        .catch(() => []);
}

export async function getStat(testNet: string): Promise<Stat> {
    return await fetch('https://dcbt.chamelyon.com/Stat/Stat' + testNetParam(testNet), { headers: headers })
        .then(res => res.ok ? res.json() : [])
        .catch(() => { });
}

export async function getUniqueId(uniqueId: string, testNet: string): Promise<StoredRecord | undefined> {
    return await fetch('https://dcbt.chamelyon.com/Explorer/UniqueId/' + uniqueId + testNetParam(testNet), { headers: headers })
        .then(res => res.ok ? res.json() : undefined)
        .catch(() => undefined);
}
