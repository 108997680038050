import { BlockTypeEnum } from "./dto/block";
import { StoredRecord } from "./dto/storedRecord";
import { normalizeSocialLinkedinText } from "./linkedin";

export const formatBigInteger = (input: string): string => {
    if (input.length <= 16) {
        return input;
    }

    const firstChars = input.slice(0, 7);
    const lastEight = input.slice(-7);

    return `${firstChars}...${lastEight}`;
}

export const formatDate = (stringDate: string): string => {
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3,6}Z$/.test(stringDate)) {
        return new Date(stringDate).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });

    }
    return stringDate;
}

const testNetParam = (testNet: string) => testNet === "" ? "" : "/testNet/" + testNet;

export const navigateTo = (paramSearch: string, paramBlocksListPage: number, paramBlocksTablePage: number, testNet: string) => {
    let r = paramSearch === "" ? "/" : "/search/" + encodeURIComponent(paramSearch);
    if (paramBlocksListPage <= 1) {
        r += paramBlocksTablePage <= 1 ? "" : "?bt=" + paramBlocksTablePage;
    }
    else {
        r += paramBlocksListPage <= 1 ? "" : "?bl=" + paramBlocksListPage;
        r += paramBlocksTablePage <= 1 ? "" : "&bt=" + paramBlocksTablePage;
    }
    return testNetParam(testNet) + r;
}

export const startFromOne = (string?: string | null) => {
    const r = Number(string ?? "1");
    return r <= 1 ? 1 : r;
}

export const capitalize = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

export const reverseArray = (arr: any[]) => arr.slice().reverse();

export const normalizePrivacyText = (text: string) => {
    text = text.replace(/\r\n/g, " ");
    text = text.replace(/\n/g, " ");
    text = text.replace(/[\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u200B\u202F\u205F\u3000\uFEFF]/g, " ");
    text = text.replace(/\s{2,}/g, " ");
    return text.trim();
};

export const normalizeTextToVerify = async (text: string, storedRecord: StoredRecord) => {
    switch (storedRecord.data.record.block.$blockType) {
        case BlockTypeEnum.Social_1_0:
            switch (storedRecord.data.record.block.social) {
                case "Linkedin":
                    return await normalizeSocialLinkedinText(text);
            }
            break;
        case BlockTypeEnum.Privacy_0_1:
            return normalizePrivacyText(text);
    }

    return text.trim();
}

// type CaseMapBlockType<T extends BlockTypeEnum, V extends GenericBlockType, R> = {
//     [key in T]?: (value: V) => R
// };

// export function switchBlockType<V extends GenericBlockType, R>(value: V, cases: CaseMapBlockType<BlockTypeEnum, V, R>, defaultCase: (value: V) => R): R {
//     const key = value.$blockType as BlockTypeEnum;
//     if (value.$blockType in cases) {
//         return cases[key]!(value);
//     } else {
//         return defaultCase(value);
//     }
// }
