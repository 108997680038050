export enum BlockTypeEnum {
    Social_1_0 = "s_chamelyon_social_1.0_json_full",
    Article_1_0 = "s_chamelyon_article_1.0_json_full",
    ArticleSubscription_1_0 = "s_chamelyon_articlesubscription_1.0_json_full",
    Contract_1_0 = "s_chamelyon_contract_1.0_json_full",
    Privacy_0_1 = "s_chamelyon_privacy_0.1_json_full",
    License_0_1 = "s_chamelyon_license_0.1_json_full",
}

// export type NotStandardBlock = {
//     readonly $blockType: string;
// }
//     & {
//         readonly [index: string]: object;
//     }
