import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/home';
import Utility from './pages/utility';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    children: [{
      path: "/search/:search?",
      element: <Home />,
    }]
  },
  {
    path: "/testnet/:testNet",
    element: <Home />,
    children: [{
      path: "/testnet/:testNet/search/:search?",
      element: <Home />,
    }]
  },
  {
    path: "/utility",
    element: <Utility />,
  }], {
  basename: "/"
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
