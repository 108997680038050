import { StoredRecord } from "../dto/storedRecord";
import { BlockTypeEnum } from "../dto/block";
import { BlockCardSocial } from "./blockCardSocial";
import { BlockCardArticle } from "./blockCardArticle";
import { BlockCardPrivacy } from "./blockCardPrivacy";
import { BlockCardGeneric } from "./blockCardGeneric";

export type BlockCardProps = {
    storedRecord: StoredRecord;
    getNavigateTo: (search: string) => string
};

export default function BlockCard(props: BlockCardProps) {
    switch (props.storedRecord.data.record.block.$blockType) {
        case BlockTypeEnum.Social_1_0:
            return <BlockCardSocial storedRecord={props.storedRecord} getNavigateTo={props.getNavigateTo} />
        case BlockTypeEnum.Article_1_0:
            return <BlockCardArticle storedRecord={props.storedRecord} getNavigateTo={props.getNavigateTo} />
        case BlockTypeEnum.Privacy_0_1:
            return <BlockCardPrivacy storedRecord={props.storedRecord} getNavigateTo={props.getNavigateTo} />
        default:
            return <BlockCardGeneric storedRecord={props.storedRecord} getNavigateTo={props.getNavigateTo} />
    }
}
