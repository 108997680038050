import { Link } from "react-router-dom";
import CopyClipboard from "./copyClipboard";
import { formatBigInteger } from "../utils";

type ShowHashProps = {
    hash: string;
    navigateTo: () => string
}

export default function ShowHash(props: ShowHashProps) {
    return <>
        <Link to={props.navigateTo()} className="code"><code>{formatBigInteger(props.hash)}</code></Link>
        <CopyClipboard text={props.hash} />
    </>;
}