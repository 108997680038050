import React, { useContext, useEffect, useRef, useState } from "react";
import { StoredRecord } from "../dto/storedRecord";
import BlockCard from "./blockCard";
import init, { hash_check } from "chamelyon-hash";
import { normalizeTextToVerify } from "../utils";
import { TestNetContext } from "../pages/home";

export type BlockListCardProps = {
    storedRecord: StoredRecord;
    selected: boolean;
    isLatestHash: boolean;
    setOpenedCard: React.Dispatch<React.SetStateAction<string>>;
    getNavigateTo: (search: string) => string
};

export function BlockListCard(props: BlockListCardProps) {
    const [verifyText, setVerifyText] = useState("");
    const [verifyResult, setVerifyResult] = useState({ message: "", error: false });
    const formRef = useRef<HTMLDivElement>(null);
    const testNet = useContext(TestNetContext);

    useEffect(() => {
        const form = formRef.current;
        if (!form) return;

        // const handleTransitionEnd = () => {
        //     form.style.display = 'none';
        //     form.style.maxHeight = '';
        //     form.removeEventListener('transitionend', handleTransitionEnd);
        //     console.log(props.storedRecord.uniqueId, "removed");
        // };
        // if (!props.selected) {
        //     console.log(props.storedRecord.uniqueId);
        //     form.addEventListener('transitionend', handleTransitionEnd);
        // }

        requestAnimationFrame(() => {
            if (props.selected) {
                form.style.maxHeight = form.scrollHeight + 10 + "px";
                form.style.padding = "10px";
            }
            else {
                form.style.maxHeight = "0";
                form.style.padding = "0";
            }
        });
    }, [props.selected, formRef]);

    useEffect(() => {
        return () => setVerifyResult({ message: "", error: false });
    }, [props.storedRecord.data.record.header.chamelyonHash.check1]);

    // useEffect(() => {
    //     const form = formRef.current;
    //     if (!form) return;

    //     const handleTransitionEnd = () => {
    //         form.style.display = 'none';
    //         form.style.maxHeight = '';
    //         console.log(form);
    //         form.removeEventListener('transitionend', handleTransitionEnd);
    //     };

    //     if (props.selected) {
    //         requestAnimationFrame(() => {
    //             form.style.maxHeight = form.scrollHeight + "px";
    //             form.style.padding = "10px";
    //         });
    //     }
    //     else {
    //         form.addEventListener('transitionend', handleTransitionEnd);
    //         form.style.maxHeight = "0";
    //         form.style.padding = "0";
    //     }
    // }, [props.selected, formRef]);

    const modified = (b: StoredRecord) => (b.data.record.block.revisions?.length ?? 0) > 0 ? "modified" : "original";

    function handleChangeVerifyText(e: React.FormEvent<HTMLTextAreaElement>) {
        setVerifyText(e.currentTarget.value);
        setVerifyResult({ message: "", error: false });
    }

    async function handleClickVerify(_: React.FormEvent<HTMLButtonElement>) {
        const q = "";
        const d = 14;
        const h = props.storedRecord.data.record.header.chamelyonHash.hash;
        const y = props.storedRecord.data.record.header.chamelyonAccount.publicKey;

        verifyResult.message = "";
        verifyResult.error = false;

        const titles = Object.keys(props.storedRecord.data.record.signatures)
            .filter((sig) => sig.includes("title"))
            .map((sig) => props.storedRecord.data.record.signatures[sig]);

        const contents = Object.keys(props.storedRecord.data.record.signatures)
            .filter((sig) => sig.includes("content"))
            .map((sig) => props.storedRecord.data.record.signatures[sig]);

        try {
            await init();

            const textToVerify = await normalizeTextToVerify(verifyText, props.storedRecord);
            if (!await verify(h, y, d, q, textToVerify, titles, contents)) {
                //TODO There are still cases where the "not blocking space" is not converted in space during the extension normalization
                await verify(h, y, d, q, textToVerify.replace(/(\.\s)([A-Z])/g, ".\u00A0$2"), titles, contents);
            }
        } catch (error) {
            console.error("Failed to check hash", error);
            setVerifyResult({ message: "Failed to verify", error: true })
        }
    }

    const testNetParam = testNet === "" ? "" : "&testNet=" + testNet;

    return <div className={"card" + (props.selected ? ' expanded' : '')}>
        <div className={'badge-side ' + modified(props.storedRecord)}>{modified(props.storedRecord)}</div>
        {props.isLatestHash && <div className="badge-top">LATEST</div>}
        <button onClick={() => props.setOpenedCard("")} className="close-button" style={{ display: props.selected ? "block" : undefined }}>&times;</button>
        <div className="card-content">
            <div className="card-left">
                <BlockCard storedRecord={props.storedRecord} getNavigateTo={props.getNavigateTo} />
            </div>
            <div className="card-right">
                <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center' }}>
                    <tbody>
                        <tr>
                            <td className="centered-cell">
                                <img height={80} src={"https://dcbt.chamelyon.com/Explorer/GetQRCode/" + props.storedRecord.uniqueId + "?noRedirect=true&margin=0&onlyImage=true" + testNetParam} alt="QR Code" className="qr-code" />
                            </td>
                        </tr>
                        <tr>
                            <td className="centered-cell">
                                <a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" href={"https://dcbt.chamelyon.com/Explorer/GetQRCode/" + props.storedRecord.uniqueId + "?noRedirect=true&margin=0" + testNetParam} className="green-button download-button">Download QR</a>
                            </td>
                        </tr>
                        <tr>
                            <td className="centered-cell">
                                <span style={{ display: props.selected ? "none" : undefined }} className="green-button verify-button" onClick={() => props.setOpenedCard(props.storedRecord.uniqueId)}>Verify</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="centered-cell">
                                <a href="https://chamelyon.com/lphowitwork/" target="_blank" rel="noreferrer">How it works</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="expanded-form" style={{ display: props.selected ? "block" : undefined }} ref={formRef}>
            <textarea rows={5} onChange={handleChangeVerifyText} value={verifyText} />
            {verifyResult.message !== "" &&
                <span className="green-button result-message" style={{ background: verifyResult.error ? "red" : undefined }}>{verifyResult.message}</span>
            }
            <button className="green-button verify-button-expanded" onClick={handleClickVerify}>Verify</button>
        </div>
    </div>

    async function verify(h: string, y: string, d: number, q: string, textToVerify: string, titles: string[], contents: string[]) {
        let titlePositions: number[] = [];
        let contentPositions: number[] = [];

        if (props.storedRecord.data.record.block.title !== null) {
            for (const [index, a] of titles.entries()) {
                const hashCheckedForTitle = hash_check(h, a, y, d, q, textToVerify);
                if (hashCheckedForTitle === true) {
                    titlePositions.push(index === 0 ? 0 : titles.length - index);
                }
            }
            prepareVerifyResultMessage(titlePositions, titles.length, "Title");
        }

        for (const [index, a] of contents.entries()) {
            const hashCheckedForContent = hash_check(h, a, y, d, q, textToVerify);
            if (hashCheckedForContent === true) {
                contentPositions.push(index === 0 ? 0 : contents.length - index);
            }
        }
        prepareVerifyResultMessage(contentPositions, contents.length, "Content");

        if (verifyResult.message === "") {
            setVerifyResult({ message: "Verify failed", error: true });
            return false;
        }
        else {
            setVerifyResult({ message: verifyResult.message, error: verifyResult.error });
            return true;
        }
    }

    function prepareVerifyResultMessage(positions: number[], numberOfRevisions: number, label: string) {
        let piece1 = "";
        let piece2 = "";
        let piece3 = "";
        if (positions.includes(0) && numberOfRevisions > 1) {
            piece1 = " in the latest version";
        }
        const revisions = positions.filter((v) => v !== 0 && v !== 1).sort();
        if (revisions.length > 0) {
            piece2 = " in revision" + (revisions.length > 1 ? "s " : " ") + revisions.join(", ");
        }
        if (positions.includes(1)) {
            piece3 = " in the original version";
        }
        if (positions.length >= 1) {
            let sep1 = "";
            let sep2 = "";

            if (piece1 !== "" && piece2 !== "") {
                sep1 = piece3 !== "" ? ", " : " and";
            }
            if (piece2 !== "" && piece3 !== "") {
                sep2 = " and";
            }
            verifyResult.message = label + piece1 + sep1 + piece2 + sep2 + piece3 + " successfully verified";
            verifyResult.error = false;
        }
    }
}