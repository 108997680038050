type AuthorProfile = {
    author: string,
    profile: string,
}

export default function AuthorOrAuthorAndProfile(props: { value: string }) {
    try {
        const authorProfile: AuthorProfile = JSON.parse(props.value);
        return <a href={authorProfile.profile} target="_blank" rel="noreferrer">{authorProfile.author}</a>;
    } catch {
        return <>{props.value}</>;
    }
}