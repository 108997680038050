import { SocialBlock } from "../dto/social";
import { formatDate } from "../utils";
import { BlockCardProps } from "./blockCard";
import StringOrLink from "./stringOrLink";
import ShowHash from "./showHash";
import AuthorOrAuthorAndProfile from "./authorOrAuthorAndProfile";

export function BlockCardSocial(props: BlockCardProps) {
    const block = props.storedRecord.data.record.block as SocialBlock;

    return <table className="card-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
            <tr>
                <td>ChaMblock:</td>
                <td><p className="chaMblock">{props.storedRecord.uniqueId} - social</p></td>
            </tr>
            <tr>
                <td>Address:</td>
                <td>
                    <ShowHash navigateTo={() => props.getNavigateTo(props.storedRecord.data.record.header.chamelyonAccount.address)} hash={props.storedRecord.data.record.header.chamelyonAccount.address} />
                </td>
            </tr>
            <tr>
                <td>Chamelyon Hash:</td>
                <td>
                    <ShowHash navigateTo={() => props.getNavigateTo(props.storedRecord.uniqueId)} hash={props.storedRecord.data.record.header.chamelyonHash.hash} />
                </td>
            </tr>
            {block.title &&
                <tr>
                    <td>Title:</td>
                    <td>
                        <p>
                            {block.title}
                        </p>
                    </td>
                </tr>
            }
            <tr>
                <td>Content:</td>
                <td><p className="content"><StringOrLink string={block.content} label={"Open the " + (block.postType ?? "content")} label2={block.postType} /></p></td>
            </tr>
            <tr>
                <td>Author:</td>
                <td><p className="author"><AuthorOrAuthorAndProfile value={block.author} /></p></td>
            </tr>
            <tr>
                <td>Social:</td>
                <td><p className="social">{block.social}{block.postType ? ": " + block.postType : ""}</p></td>
            </tr>
            <tr>
                <td>AI Generated:</td>
                <td><p className="aiGenerated">{((block.aiGenerated ?? 0) * 100).toFixed(1)}%</p></td>
            </tr>
            <tr>
                <td>Timestamp:</td>
                <td><p className="timestamp">{formatDate(block.timestamp)}</p></td>
            </tr>
        </tbody>
    </table>;
}