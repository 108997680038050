import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface GraphProps {
    data: any;
    title: string;
    height?: number;
}

const Graph: React.FC<GraphProps> = ({ data, title, height }) => {
    const max = Math.max(...data.datasets[0].data, ...data.datasets[1].data);

    const graphOptions = {
        responsive: height === undefined,
        layout: {
            padding: 0
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: title
            },
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    color: 'rgba(200, 200, 200, 0.2)',
                },
            },
            y: {
                min: 0,
                max: Math.trunc(max * 1.2),
                beginAtZero: true,
                grid: {
                    display: true,
                    color: 'rgba(200, 200, 200, 0.2)',
                },
            },
        },
    };

    return <Line className='graph' data={data} options={graphOptions} height={height ?? 100} />
};

export default Graph;