import { ArticleBlock } from "../dto/article";
import { formatDate } from "../utils";
import { BlockCardProps } from "./blockCard";
import StringOrLink from "./stringOrLink";
import ShowHash from "./showHash";


export function BlockCardArticle(props: BlockCardProps) {
    const block = props.storedRecord.data.record.block as ArticleBlock;

    return <table className="card-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
            <tr>
                <td>ChaMblock:</td>
                <td><p className="chaMblock">{props.storedRecord.uniqueId} - article</p></td>
            </tr>
            <tr>
                <td>Chamelyon Hash:</td>
                <td>
                    <p>
                        <ShowHash navigateTo={() => props.getNavigateTo(props.storedRecord.uniqueId)} hash={props.storedRecord.data.record.header.chamelyonHash.hash} />
                    </p>
                </td>
            </tr>
            <tr>
                <td>Title:</td>
                <td>
                    <p>
                        {block.title}
                    </p>
                </td>
            </tr>
            <tr>
                <td>Content:</td>
                <td><p className="content"><StringOrLink string={block.uri ?? block.content} label={"Open the article"} /></p></td>
            </tr>
            <tr>
                <td>Author:</td>
                <td><p className="author">{block.author}</p></td>
            </tr>
            <tr>
                <td>Newspaper:</td>
                <td><p className="social">{block.newspaper}</p></td>
            </tr>
            <tr>
                <td>Timestamp:</td>
                <td><p className="timestamp">{formatDate(block.timestamp)}</p></td>
            </tr>
        </tbody>
    </table>;
}